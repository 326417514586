import axios from "axios";

export const getContato = async () => {
  try {
    const contato = axios({
      url: `${process.env.REACT_APP_API_URL}/pages/contato`,
      method: "GET",
    });
    return contato;
  } catch (err) {
    return err;
  }
};

export const sendMail = async (data) => {
  try {
    const contato = axios({
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: data,
      method: "POST",
    });
    return contato;
  } catch (err) {
    return err;
  }
};
