import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./app.scss";
// Pages
import { Home } from "./pages/Home";
import { Contato } from "./pages/Contato";
import { Sobre } from "./pages/Sobre";
import { AreaAtuacao } from "./pages/AreaAtuacao";
import { Portfolio } from "./pages/Portfolio";

// Componentes
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/contato">
          <Contato />
        </Route>
        <Route path="/areas-atuacao">
          <AreaAtuacao />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/sobre">
          <Sobre />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
