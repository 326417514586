import React from "react";
import "./card-atuacao.scss";

export const CardAtuacao = (props) => {
  return (
    <div className="card-atuacao">
      <div className="card-atuacao__content">
        <span className="card-atuacao__text">{props.text}</span>
      </div>
    </div>
  );
};
