import React from "react";
import IconePlus from "../../assets/images/home/more.svg";
import "./card.scss";

export const Card = (props) => {
  return (
    <div className="card" onClick={() => props.onClick()}>
      <div className="card__container">
        <div className="card__content">
          <h3 className="card__title">{props.title}</h3>
          <h4 className="card__subtitle">{props.subtitle}</h4>
        </div>
        <div className="card__image">
          <img src={props.image} alt="card imagem" />
        </div>
        <div className="card__overlay" />
      </div>
    </div>
  );
};

export const CardPlus = (props) => {
  return (
    <div className="card card-plus" onClick={() => props.onClick()}>
      <div className="card__container">
        <div className="card__content">
          <img className="card-plus__icon" src={IconePlus} alt="Plus" />
          <h3 className="card__title">{props.title}</h3>
        </div>
        <div className="card__overlay" />
      </div>
    </div>
  );
};
