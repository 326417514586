import React from "react";
import "./alert.scss";
import { Button } from "../Button";
export const Alert = (props) => {
  return (
    <>
      {props.show && (
        <div className="alert">
          <div className="alert__content">
            <h3 className="alert__title">Mensagem Enviada</h3>
            <p className="alert__message">
              Sua mensagem foi enviada e logo entraremos em contato
            </p>
            <Button
              text="Fechar"
              onClick={() => {
                props.close();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
