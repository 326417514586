import React, { useEffect, useState } from "react";
import { Hero } from "../../components/Hero";
import { CardAtuacao } from "../../components/CardAtuacao";
import { getServicos } from "../../api/servicos";

export const AreaAtuacao = () => {
  const [servicosData, setServicosData] = useState();

  useEffect(() => {
    getServicos().then((res) => {
      setServicosData(res.data);
    });
  }, []);
  return (
    <div className="container">
      {servicosData && (
        <>
          <Hero
            imageDesktop={servicosData.heroDesktop.sizes.herodesktop}
            imageMobile={servicosData.heroMobile.sizes.heromobile}
            title={servicosData.title}
          />
          <div className="container container--cinza container--padding-content">
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: servicosData.text1,
                }}
              />
              <div className="container">
                <div className="content content--grid">
                  {servicosData.services.map((res, index) => (
                    <CardAtuacao key={index} text={res.servicosatuacaotexto} />
                  ))}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: servicosData.text2,
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
