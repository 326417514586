import axios from "axios";

export const getPortfolio = async () => {
  try {
    const portfolio = axios({
      url: `${process.env.REACT_APP_API_URL}/pages/portfolio`,
      method: "GET",
    });
    return portfolio;
  } catch (err) {
    return err;
  }
};
