import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.scss";
import logo from "../../assets/images/logo.svg";
import CloseButton from "../../assets/images/close-button.svg";

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onMenu = () => {
    setShowMenu(!showMenu);
  };

  const itensMenu = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Sobre",
      link: "/sobre",
    },
    {
      label: "Áreas de atuação",
      link: "/areas-atuacao",
    },
    {
      label: "Portfólio",
      link: "/portfolio",
    },
    {
      label: "Contato",
      link: "/contato",
    },
  ];
  return (
    <div className="header">
      <div className="header__content">
        <a href="/">
          <img className="header__logo" src={logo} alt="Logo" />
        </a>
        <div
          className={showMenu ? "menu menu--active" : "menu"}
          onClick={() => onMenu()}
        >
          {showMenu && (
            <button className="botao-close-hamburguer">
              <img src={CloseButton} alt="fechar" />
            </button>
          )}
          {itensMenu.map((res, index) => (
            <NavLink
              exact={true}
              key={index}
              activeClassName="menu__link--active"
              className="menu__link"
              to={res.link}
            >
              {res.label}
            </NavLink>
          ))}
        </div>
        <button className="botao-hamburguer" onClick={() => onMenu()}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  );
};
