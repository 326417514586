import React, { useEffect, useState } from "react";
import { getContato } from "../../api/contato";
import { Contact } from "../../components/Contact";
import { Hero } from "../../components/Hero";

export const Contato = () => {
  const [contatoData, setContatoData] = useState();

  useEffect(() => {
    getContato().then((res) => {
      setContatoData(res.data);
    });
  }, []);

  return (
    <div className="container">
      {contatoData && (
        <>
          <Hero
            imageDesktop={contatoData.heroDesktop.sizes.herodesktop}
            imageMobile={contatoData.heroMobile.sizes.heromobile}
          />
          <Contact />
        </>
      )}
    </div>
  );
};
