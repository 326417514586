import axios from "axios";

export const getServicos = async () => {
  try {
    const servicos = axios({
      url: `${process.env.REACT_APP_API_URL}/pages/servicos`,
      method: "GET",
    });
    return servicos;
  } catch (err) {
    return err;
  }
};
