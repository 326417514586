import React, { useEffect, useState } from "react";
import "./hero.scss";

// Components
import { Button } from "../Button";

export const Hero = (props) => {
  const [image, setImage] = useState("");
  let sizeViewport = window.innerWidth;

  useEffect(() => {
    sizeViewport > 768
      ? setImage(props.imageDesktop)
      : setImage(props.imageMobile);
  }, [sizeViewport, props.imageMobile, props.imageDesktop]);

  const getClass = () => {
    if (props.left) {
      return "hero__container hero__container--left";
    } else {
      return "hero__container";
    }
  };

  return (
    <div className="hero">
      {props.title && (
        <div className={getClass()}>
          <div className="hero__content">
            <h1 className="hero__title">{props.title}</h1>
            {props.button && (
              <Button text={props.button} onClick={props.clickButton} />
            )}
          </div>
        </div>
      )}
      {image && <img className="hero__image" src={image} alt="Imagem Hero" />}
      <div className="hero__overlay" />
    </div>
  );
};
