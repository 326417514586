import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getHome } from "../../api/home";

// Components
import { Hero } from "../../components/Hero";
import { Card, CardPlus } from "../../components/Card";
import { Contact } from "../../components/Contact";

export const Home = () => {
  let history = useHistory();

  const [homeData, setHomeData] = useState();

  useEffect(() => {
    getHome().then((res) => {
      setHomeData(res.data);
    });
  }, []);

  const goTo = (to) => {
    history.push(to);
  };
  return (
    <div className="container">
      {homeData && (
        <>
          <Hero
            left
            imageDesktop={homeData.heroDesktop.sizes.herodesktop}
            imageMobile={homeData.heroMobile.sizes.heromobile}
            title={homeData.title}
            button={homeData.botaoContato}
            clickButton={() => {
              history.push("/contato");
            }}
          />
          <div className="container container--cinza container--padding-content">
            <div className="content content--grid">
              {homeData.services &&
                homeData.services.map((res, index) => (
                  <Card
                    onClick={() => {
                      goTo("areas-atuacao");
                    }}
                    key={index}
                    image={res.atuacaoimagem.sizes.thumbcard}
                    title={res["titulo-atuacao-home"]}
                    subtitle={res["subtitulo-atuacao-home"]}
                  />
                ))}
              <CardPlus
                onClick={() => {
                  goTo("areas-atuacao");
                }}
                title="Veja todas as áreas de atuação"
              />
            </div>
          </div>
          <Hero
            imageDesktop={homeData.heroDesktop2.sizes.herodesktop}
            imageMobile={homeData.heroMobile2.sizes.heromobile}
            title={homeData.title2}
            button={homeData.botaoContato}
            clickButton={() => {
              history.push("/contato");
            }}
          />
        </>
      )}
      ;
      <Contact />
    </div>
  );
};
