import React, { useEffect, useState } from "react";
import { getContato, sendMail } from "../../api/contato";

import "./contact.scss";

// Components
import { Button } from "../Button";
import { Loading } from "../Loadign";
import { Alert } from "../Alert";

export const Contact = () => {
  const [contatoData, setContatoData] = useState();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getContato().then((res) => {
      setContatoData(res.data);
      console.log(res.data);
    });
  }, []);

  const closeAlert = () => {
    setShowAlert(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!nome && !email && !mensagem) {
      return;
    }
    setShowLoading(true);
    let data = {
      service_id: "service_se4096m",
      template_id: "template_zi2a3gs",
      user_id: "user_W0GSBuOvRb0FvEx0nGmp8",
      template_params: {
        to_name: "Rafael Ferreira",
        from_name: nome,
        from_email: email,
        from_assunto: assunto,
        from_message: mensagem,
      },
    };
    sendMail(data)
      .then((res) => {
        setShowLoading(false);
        setShowAlert(true);
        setNome("");
        setEmail("");
        setAssunto("");
        setMensagem("");
      })
      .catch((res) => {
        setShowLoading(false);
      });
  };

  return (
    <div className="contact">
      <Loading show={showLoading} />
      <Alert show={showAlert} close={() => closeAlert()} />
      {contatoData && (
        <>
          <div className="contact__content">
            <div className="contact__item">
              <h3 className="contact__title">{contatoData.title}</h3>
              <div
                className="contact__text"
                dangerouslySetInnerHTML={{ __html: contatoData.text }}
              />
              <h4 className="contact__text">{contatoData.phone}</h4>
            </div>
            <div className="contact__item">
              <form onSubmit={(e) => handleSubmit(e)} className="contact__form">
                <input
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  placeholder="Nome"
                  className="contact__input"
                />
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail"
                  className="contact__input"
                />
                <input
                  value={assunto}
                  onChange={(e) => setAssunto(e.target.value)}
                  placeholder="Assunto"
                  className="contact__input"
                />
                <textarea
                  required
                  value={mensagem}
                  onChange={(e) => setMensagem(e.target.value)}
                  placeholder="Mensagem"
                  className="contact__textarea"
                />
                <Button type="submit" text="enviar" />
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
