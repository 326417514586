import React, { useState, useEffect } from "react";
import { getContato } from "../../api/contato";
import "./footer.scss";

// Images
import Logo from "../../assets/images/logo.svg";

export const Footer = () => {
  const [contatoData, setContatoData] = useState();

  useEffect(() => {
    getContato().then((res) => {
      setContatoData(res.data);
      console.log(res.data);
    });
  }, []);

  return (
    <footer className="footer">
      {contatoData && (
        <>
          <div className="footer__content">
            <img src={Logo} alt="Logo" />
            <h3 className="footer__phone">{contatoData.phone}</h3>
          </div>
        </>
      )}
      {contatoData && (
        <p className="footer__direitos">{contatoData.direitosReservados}</p>
      )}
    </footer>
  );
};
