import React from "react";
import "./modal.scss";
import CloseIcon from "../../assets/images/close-modal.svg";
export const Modal = (props) => {
  return (
    <>
      {props.show && (
        <div className="modal">
          <div className="modal__content">
            <button className="modal__close" onClick={() => props.closeModal()}>
              <img src={CloseIcon} alt="fechar modal" />
            </button>
            <h3 className="modal__title">{props.title}</h3>
            <div
              className="modal__text"
              dangerouslySetInnerHTML={{
                __html: props.text,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
