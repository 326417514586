import React, { useState, useEffect } from "react";
import { getPortfolio } from "../../api/portfolio";

// Components
import { Hero } from "../../components/Hero";
import { Card } from "../../components/Card";
import { Modal } from "../../components/Modal";

export const Portfolio = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [portfolioData, setPortfolioData] = useState();

  useEffect(() => {
    getPortfolio().then((res) => {
      setPortfolioData(res.data);
    });
  }, []);

  const openModal = (content) => {
    setShowModal(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent([]);
  };

  return (
    <div className="container">
      {portfolioData && (
        <>
          <Modal
            title={modalContent.tituloportfolio}
            text={modalContent.textoportfolio}
            show={showModal}
            closeModal={() => closeModal()}
          />
          <Hero
            imageDesktop={portfolioData.heroDesktop.sizes.herodesktop}
            imageMobile={portfolioData.heroMobile.sizes.heromobile}
            title={portfolioData.title}
          />
          <div className="container container--cinza container--padding-content">
            <div className="content content--grid">
              {portfolioData.portfolio.map((res, index) => (
                <Card
                  key={index}
                  image={res.imagemportfolio.sizes.thumbcard}
                  title={res.tituloportfolio}
                  onClick={() => openModal(res)}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
