import React, { useState, useEffect } from "react";
import { Hero } from "../../components/Hero";
import { getSobre } from "../../api/sobre";

export const Sobre = () => {
  const [sobreData, setSobreData] = useState();

  useEffect(() => {
    getSobre().then((res) => {
      setSobreData(res.data);
    });
  }, []);

  return (
    <div className="container">
      {sobreData && (
        <>
          <Hero
            imageDesktop={sobreData.heroDesktop.sizes.herodesktop}
            imageMobile={sobreData.heroMobile.sizes.heromobile}
            title={sobreData.title}
          />
          <div className="container container--cinza container--padding-content">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: sobreData.text,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
