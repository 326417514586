import React from "react";
import "./loading.scss";

export const Loading = (props) => {
  return (
    <>
      {props.show && (
        <div className="loading">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>Enviando Mensagem</p>
        </div>
      )}
    </>
  );
};
