import axios from "axios";

export const getSobre = async () => {
  try {
    const sobre = axios({
      url: `${process.env.REACT_APP_API_URL}/pages/sobre`,
      method: "GET",
    });
    return sobre;
  } catch (err) {
    return err;
  }
};
